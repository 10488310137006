// const constants. BASE_URL = "https://uat.idesign.market";

import { constants } from "../../../piservices/constants";

export const headerArr = [
  {
    label: "Home",
    href: `${constants.BASE_URL}/admin/`,
    accessName: "default",
    active: window.location.pathname.includes("admin") ? true : false,
  },
  {
    label: "Leads",
    href: `${constants.BASE_URL}/lms/`,
    accessName: "crm",

    active: window.location.pathname.includes("lms") ? true : false,
  },
  {
    label: "Projects",
    href: `${constants.BASE_URL}/pmt/`,
    accessName: "default",
    drop: true,
    active: window.location.pathname.includes("pmt") ? true : false,
  },
  {
    label: "Tools",
    href: `${constants.BASE_URL}/tools/`,
    accessName: "default",
    active: false,
  },
  {
    label: "Chat",
    link: "",
    href: `${constants.BASE_URL}/updates/`,
    accessName: "default",
    active: false,
  },

  {
    label: "Tasks",
    link: "",
    href: `${constants.BASE_URL}/timeline/my-task/`,
    accessName: "default",
    active: false,
  },

  {
    label: "Purchase",
    link: "",
    href: `${constants.BASE_URL}/po/global-po`,
    accessName: "default",
    active: false,
  },
  {
    label: "Book Keeping",
    link: "",
    href: `${constants.BASE_URL}/account/project-summary`,
    accessName: "accounts",
    active:false,
},
];
