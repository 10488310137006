export function whatsAppApi(number) {
  return `https://api.whatsapp.com/send?phone=91${number}&text=Hi%2C+Greetings`;
}

var currentURL = window.location.href;
var thirdSlashIndex = currentURL.indexOf("/", currentURL.indexOf("/") + 2); // Find the index of the second slash
var slicedURL = currentURL.substring(0, thirdSlashIndex); // Extract substring from beginning till the third slash
console.log(slicedURL);
const url = new URL(slicedURL);
const hostname = url.hostname;
const parts = hostname.split(".");
const substring = parts.slice(1).join(".");

export const constants = {
  CRM_API: `https://api.${substring}.idesign.market/b2b`,
  PO_API: `https://api.${substring}.idesign.market/po`,
  PMT_API: `https://api.${substring}.idesign.market/pmt`,
  QUO_API: `https://api.${substring}.idesign.market/quotation`,
  BASE_URL: `https://erp.${substring}`,
  CHAT_API: `https://api.${substring}.idesign.market/chat`,
  S3_API: `https://api.${substring}.idesign.market/s3`,
  NOTIFY_API: `https://api.${substring}.idesign.market/notification`,
  EMAIL_API: `https://api.${substring}.idesign.market/email`,
};
if (
  window.location.origin == "http://localhost:3000" ||
  window.location.origin == "http://localhost:3001" ||
  window.location.origin == "http://localhost:3002" ||
  window.location.origin == "http://localhost:3003" ||
  window.location.origin == "http://localhost:3007"
) {
  constants.CRM_API = "https://api.production.idesign.market/b2b";
  constants.PMT_API = "https://api.production.idesign.market/pmt";
  constants.QUO_API = "https://api.production.idesign.market/quotation";
  constants.PO_API = "https://api.production.idesign.market/po";
  constants.S3_API = "https://api.production.idesign.market/s3";
  constants.CHAT_API = "https://api.production.idesign.market/chat";
  constants.NOTIFY_API = "https://api.production.idesign.market/notification";
  constants.EMAIL_API = "https://api.production.idesign.market/email";
  constants.BASE_URL = "http://localhost:3001"; // Corrected BASE_URL for localhost
} else if (slicedURL === "https://pro.idesign.market") {
  constants.CRM_API = "https://api.idesign.market/b2b";
  constants.PMT_API = "https://api.idesign.market/pmt";
  constants.QUO_API = "https://api.idesign.market/quotation";
  constants.S3_API = "https://api.idesign.market/s3";
  constants.CHAT_API = "https://api.idesign.market/chat";
  constants.NOTIFY_API = "https://api.idesign.market/notification";
  constants.BASE_URL = "https://pro.idesign.market";
  constants.PO_API = "https://api.idesign.market/po";
  constants.EMAIL_API = "https://api.idesign.market/email";
} else if (slicedURL === "https://pulse.anj.co.in") {
  constants.CRM_API = `https://api.${substring}.idesign.market/b2b`;
  constants.PO_API = `https://api.${substring}.idesign.market/po`;
  constants.PMT_API = `https://api.${substring}.idesign.market/pmt`;
  constants.QUO_API = `https://api.${substring}.idesign.market/quotation`;
  constants.BASE_URL = `https://pulse.${substring}`;
  constants.CHAT_API = `https://api.${substring}.idesign.market/chat`;
  constants.S3_API = `https://api.${substring}.idesign.market/s3`;
  constants.NOTIFY_API = `https://api.${substring}.idesign.market/notification`;
  constants.EMAIL_API = `https://api.${substring}.idesign.market/email`;
} else if (slicedURL === 'https://erp.dev.idesign.market') {
  constants.CRM_API = 'https://api.production.idesign.market/b2b'
  constants.PMT_API = 'https://api.production.idesign.market/pmt'
  constants.QUO_API = 'https://api.production.idesign.market/quotation'
  constants.PO_API = 'https://api.production.idesign.market/po'
  constants.S3_API = 'https://api.production.idesign.market/s3'
  constants.CHAT_API = 'https://api.production.idesign.market/chat'
  constants.NOTIFY_API = 'https://api.production.idesign.market/notification'
  constants.EMAIL_API = 'https://api.production.idesign.market/email'
  constants.BASE_URL = `https://erp.${substring}`
}

export const iDesignWebsite = "https://www.idesign.market";
// export const constants. BASE_URL = "https://uat.idesign.market";

// export default expf;

export const signUpValidationObject = {
  password: {
    required: true,
    minLength: 8,
    maxLength: 15,
    upperCasePattern: /[A-Z]/,
    specialCharacterPattern: /[!@#$%^&*()_+{}[\]:;<>,.?~|/-]/,
  },
};

export const signInValidationObject = {
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
  password: {
    required: true,
  },
};

export const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const emailValidation = {
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
};

export const adminPersonalDataValObj = {
  fname: {
    required: true,
  },
  lName: {
    required: true,
  },
  address: {
    required: true,
  },
  city: {
    required: true,
  },
  zipCode: {
    required: true,
    minLength: 6,
    maxLength: 6,
  },
  birth: {
    required: true,
  },
  number: {
    required: true,
  },
};

export const verifyOtpValObj = {
  otp: {
    required: true,
    minLength: 4,
    maxLength: 4,
  },
};

export const teamOnboardVerifyObj = {
  fName: {
    required: true,
    maxLength: 15,
  },
  Lname: {
    required: true,
    maxLength: 15,
  },
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },

  role: {
    required: true,
  },

  designation: {
    required: true,
  },
};

export const getOtpVerifyObj = {
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
};
